import Props from '@common/Props.interface'
import { Size } from '@components/shared'
import ImageSize from '@components/shared/ImageSize'
import Icon from '@icons/Icon'
import resolveImageSize from '@utils/resolveImageSize'
import Image from 'next/image'

import { ITestimonial } from './Testimonial.interface'

export interface TestimonialProps extends Props {
  testimonial: ITestimonial
  imageSize?: ImageSize
}

const Testimonial = ({
  className = '',
  testimonial,
  imageSize = ImageSize.MEDIUM,
}: TestimonialProps) => {
  const sizedImage = resolveImageSize(testimonial.image, imageSize)
  return (
    <div
      className={`relative flex h-full flex-col items-center justify-items-stretch gap-10 rounded-2xl bg-white p-5 shadow-xl transition-all md:flex-row md:items-stretch md:justify-between md:p-10 ${className}`}
    >
      <div className="relative mx-5 h-full min-h-[300px] w-full md:ml-0 md:h-[300px] md:max-w-[320px]">
        {sizedImage && (
          <Image
            src={sizedImage.url}
            placeholder="blur"
            blurDataURL={testimonial.image.blurhash}
            className="rounded-2xl object-cover object-center"
            fill
            alt={testimonial.image.alternativeText ?? ''}
          />
        )}
      </div>
      <div className="flex flex-col items-center justify-center gap-5 md:items-start">
        <div className="flex flex-col items-center gap-1 font-bold md:items-start">
          <span className="text-2xl md:text-xl">{testimonial.name}</span>
          <span className="text-sm text-green-500">{testimonial.description}</span>
        </div>

        <p className="max-w-screen-sm">{testimonial.text}</p>

        <div className="flex items-center gap-5 text-green-500">
          <div className="relative">
            <div className="flex gap-2">
              <Icon icon="star" size={Size.SMALL} />
              <Icon icon="star" size={Size.SMALL} />
              <Icon icon="star" size={Size.SMALL} />
              <Icon icon="star" size={Size.SMALL} />
              <Icon icon="star" size={Size.SMALL} />
            </div>
            <div
              className="absolute bottom-0 right-0 top-0 bg-green-100 mix-blend-screen"
              style={{ width: `${(10 - testimonial.rating) * 10}%` }}
            />
          </div>
          <span className="pt-1.5">{testimonial.rating}/10</span>
        </div>
      </div>
    </div>
  )
}

export default Testimonial
