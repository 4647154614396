'use client'

import Props from '@common/Props.interface'
import { Size } from '@components/shared'
import Icon from '@icons/Icon'
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import { useEffect } from 'react'

import Testimonial from './Testimonial'
import { ITestimonial } from './Testimonial.interface'

export interface TestimonialProps extends Props {
  testimonials: ITestimonial[]
}

const Testimonials = ({ className = '', testimonials = [] }: TestimonialProps) => {
  useEffect(() => {
    // Workaround initial load bug: https://github.com/kodingdotninja/use-tailwind-breakpoint/issues/2
    window.dispatchEvent(new Event('resize'))
  }, [])

  const current = Math.floor(testimonials.length / 2)

  const visibleSlides = 1
  const controls =
    visibleSlides >= testimonials.length ? (
      ''
    ) : (
      <div className="-mt-16 hidden h-fit justify-between px-8 pb-8 text-green-500 lg:flex">
        <ButtonBack>
          <Icon icon="arrow-left" size={Size.SMALL} className="hover:text-green-300" />
        </ButtonBack>

        <ButtonNext>
          <Icon icon="arrow-right" size={Size.SMALL} className="hover:text-green-300" />
        </ButtonNext>
      </div>
    )

  return (
    <CarouselProvider
      key={visibleSlides}
      totalSlides={testimonials.length}
      visibleSlides={visibleSlides}
      currentSlide={current}
      naturalSlideWidth={1080}
      naturalSlideHeight={400}
      className={className}
      isIntrinsicHeight
      lockOnWindowScroll
      infinite
    >
      {controls}

      <Slider className="px-0 pb-10 lg:px-[10%] xl:px-[25%]">
        {testimonials.map((testimonial: ITestimonial, index: number) => {
          return (
            <Slide index={index} key={testimonial.id} arial-label="testimonial" className="px-7.5">
              <Testimonial testimonial={testimonial} />
            </Slide>
          )
        })}
      </Slider>
    </CarouselProvider>
  )
}

export default Testimonials
